import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { rhythm } from "../utils/typography";

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { social } = data.site.siteMetadata;
        return (
          <div
            style={{
              display: "flex",
              marginBottom: rhythm(2),
            }}
          >
            <p style={{ marginBottom: 0 }}>
              thoughts about frontend dev, digital experiences and education{" "}
              <br />
              <a href={`https://twitter.com/${social.twitter}`}>
                Follow me on Twitter
              </a>
            </p>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
